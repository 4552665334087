import React from "react";

import { Spinner } from "react-bootstrap";

const styles = { height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" };

const Loading = () => (
  <div style={styles}>
    <Spinner animation="border" variant="primary" />
  </div>
);

export default Loading;
