import "./App.css";
import { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataContext } from "./Model/DataContext";
import { ToastContainer } from "react-toastify";
import LoadableComponent from "./Components/common/loadable";

const Home = LoadableComponent(() => import("./Pages/Home/Home"));
const About = LoadableComponent(() => import("./Pages/About/About"));
const Contact = LoadableComponent(() => import("./Pages/Contact/Contact"));
const Career = LoadableComponent(() => import("./Pages/Career/Career"));
const Faq = LoadableComponent(() => import("./Pages/Faq/Faq"));
const NotFound = LoadableComponent(() => import("./Pages/NotFound/NotFound"));
const Terms = LoadableComponent(() => import("./Pages/Terms/Terms"));
const Brands = LoadableComponent(() => import("./Pages/Brands/Brands"));
const Cars = LoadableComponent(() => import("./Pages/Cars/Cars"));
const Car = LoadableComponent(() => import("./Pages/Car/Car"));
const CarSpecification = LoadableComponent(() =>
  import("./Pages/CarSpecification/CarSpecification")
);
const Order = LoadableComponent(() => import("./Pages/Order/Order"));
const News = LoadableComponent(() => import("./Pages/news"));

function App() {
  const { language } = useContext(DataContext);

  return (
    <div style={{ direction: language.system.dir }}>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/cars" element={<Cars />} />
          <Route path="/car/:id" element={<Car />} />
          <Route path="/car-specification/:id" element={<CarSpecification />} />
          <Route path="/order" element={<Order />} />
          <Route path="/news" element={<News />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
