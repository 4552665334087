import React from "react";

import Loadable from "@loadable/component";

import Loading from "../loading";

const LoadableComponent = (component, opts = {}) =>
  Loadable(component, { fallback: <Loading />, ...opts });

export default LoadableComponent;
