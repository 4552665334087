export const Arabic = {
  viewAll: "مشاهدة الكل",
  current: "ar",
  name: "عربي",
  header: {
    home: "الرئيسية",
    brand: {
      brand: "الماركات",
      nissan: "نيسان",
      toyota: "تويوتا",
      lexus: "لكزس",
      volkswagen: "فولكسواجن",
      honda: "هوندا",
      mitsubishi: "ميتسوبيشي",
      suzuki: "سوزوكي",
    },
    car_type: {
      car_type: "نوع السيارة",
      micro: "مايكرو",
      sedan: "سيدان",
      hatchback: "هاتشباك",
      suv: "سيارات الدفع الرباعي",
      pickup: "سيارات الشحن",
      support: "رياضية",
    },
    about_company: {
      about_company: "عن الشركة",
      about: "من نحن",
      contact: "تواصل معنا",
      career: "الوظائف",
      faq: "الأسئلة الشائعة",
      company_profile: "ملف الشركة",
    },
    allStock: "كل المخزون",
    enquiries: "الاستفسارات",
    otherLanguages: [
      {
        id: "en",
        label: "English",
      },
      {
        id: "ru",
        label: "Русский",
      },
      {
        id: "fr",
        label: "Français",
      },
    ],
    blog: "مدونة السيارات"
  },

  home: {
    hero: {
      more_details: "مزيد من التفاصيل",
      search: {
        title: "بحث",
        more_filter_mobile: "عرض كل الفلاتر",
        more_filter: "عرض_الفلاتر",
        less_filter: "عرض_اقل",
        maker: {
          title: "الصانع",
          nissan: "Nissan",
          toyota: "Toyota",
          lexus: "Lexus",
          volkswagen: "Volkswagen",
          mitsubishi: "Mitsubishi",
          suzuki: "Suzuki",
        },
        model: {
          title: "موديل",
          sunny: "Sunny",
          altima: "Altima",
          maxima: "Maxima",
          patrol: "Patrol",
          patrol_safari: "Patrol Safari",
          pathfinder: "Pathfinder",
        },
        year: {
          title: "السنة",
          min: "الحد الأدنى للسنة",
          max: "الحد الاعلي للسنة ",
        },
        status: {
          title: "الحالة",
          all: "الكل",
          new: "جديد",
          used: "مستعمل",
        },
        fuel: {
          title: "الوقود",
          all: "الكل",
          gasoline: "Gasoline",
          diesel: "Diesel",
          hybrid: "Hybrid",
          electric: "Electric",
        },
        cylinder: {
          title: "الاسطوانات",
          all: "الكل",
          none: "بلا",
        },
        gcc: {
          title: "جي سي سي",
          gcc: "جي سي سي",
          import: "مستورد",
        },
        body_type: {
          title: "نوع الهيكل",
          micro: "صغير",
          hatchback: "هاتشباك",
          sedan: "سيدان",
          suv: "سيارات الدفع الرباعي",
          pickup: "سيارات نقل",
          sport: "رياضية",
        },
        color: {
          title: "اللون",
          white: "White",
          black: "Black",
          blue: "Blue",
          red: "Red",
          gray: "Gray",
          silver: "Silver",
          green: "Green",
          brown: "Brown",
        },
        gear_box: {
          title: "ناقل الحركة",
          all: "الكل",
          automatic: "Automatic",
          manual: "Manual",
        },
        seats: {
          title: "المقاعد",
          all: "الكل",
        },
      },
    },
    premium: "السيارات مميزة",
    new: "السيارات الجديدة",
    popular: "السيارات الاكثر شيوعا",
    used: "السيارات المستعملة",
    offer: "عروضنا",
    export: "سيارة للتصدير",
    partner: "شركاؤنا",
    showroom: "سيارات للمعارض الأخرى",
    news: "أخبار السيارات",
    last_review: "اخر التقيمات",
    social_media: "وسائل الاعلام الاجتماعية",
    instagram: "انستغرام",
    location: {
      title: "موقعنا",
      title1: "معرض - رأس الخور - دبي - الإمارات العربية المتحدة",
      title2: "معرض - رأس الخور - دبي - الإمارات العربية المتحدة",
      title3: "معرض - رأس الخور - دبي - الإمارات العربية المتحدة",
    },
  },

  about: {
    values: "قيمنا الأساسية",
    team: "فريقنا",
  },

  contact: {
    support: "24/7 دعم",
    have_questions: "لديك أي أسئلة؟",
    ask_us: "اسألنا..!!",
    get_quote: " تسعيرة ",
    form: {
      name: "الاسم*",
      name_validate: "حقل الاسم مطلوب.",
      email: "البريد الإلكتروني",
      email_validate: "يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا.",
      phone: "الهاتف*",
      phone_validate: "حقل الهاتف مطلوب.",
      country: "حدد الدولة",
      country_validate: "حقل البلد مطلوب.",
      message: "رسالة ...",
      message_validate: "حقل الرسالة مطلوب.",
      button_text: "أرسل رسالة",
      result_validate: "شكرًا ، ستحصل على عرض السعر عبر بريدك الإلكتروني",
      error_validate: "اختر تفاصيل السيارة رجاءً",
    },
  },

  career: {
    title: "وظائف",
    sub_title:
      "لم تجد وظيفة شاغرة مناسبة ومتاحة؟ أرسل سيرتك الذاتية باستخدام النموذج أدناه أو أرسل سيرتك الذاتية إلى: careers@alkarama.com",
    job_title: "المسمى الوظيفي",
    position: "الوصف الوظيفي",
    job_description: "تفاصيل الوظيفة",
    description: "تفاصيل",
    no_job: "لا توجد وظائف حالياً",
    submit: "قدم الان",
    apply: "قدم",
    email: "careers@alkarama.com",
    form: {
      name: "الاسم*",
      name_validate: "حقل الاسم مطلوب.",
      email: "البريد الإلكتروني",
      email_validate: "يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا.",
      phone: "الهاتف*",
      phone_validate: "حقل الهاتف مطلوب.",
      country: "حدد الدولة",
      country_validate: "حقل البلد مطلوب.",
      cv: "السيرة الذاتية*",
      cv_validate: "قم بتحميل سيرتك الذاتية من فضلك.",
      result_succed: "تم إرسال طلب عملك بنجاح.",
      button_text: "إرسال",
    },
  },

  car: {
    menu: {
      specs: "المواصفات",
      gallery: "المعرض",
      about: "عن السيارة",
      colors: "الألوان",
      ask_us: "تواصل معنا",
      similar: "سيارات مماثلة",
      all: "الجميع",
      interior: "الداخل",
      exterior: "الخارج",
      video: "فيديو",
    },
    specs: {
      downloadPdf: "تحميل PDF",
      year: "السنة: ",
      mileage: "عدد الأميال: ",
      specs: "المواصفات: ",
      fuel: "الوقود: ",
      engine: "المحرك: ",
      doors: "الابواب: ",
      transmission: "الناقل: ",
      speed: "السرعة: ",
      drive: "الدفع: ",
      seats: "المقاعد: ",
      steeringSide: "جانب التوجيه: ",
      similar: "عرض المواصفات الكاملة",
      back: "العودة إلى السيارة",
    },
    similar: "مركبات مماثلة",
    interior: "تصميم داخلي",
    exterior: "الميزات الخارجية",
    environment: "الأمان والبيئة",
  },

  brand: {
    title: "العلامات التجارية",
  },

  order: {
    track: "متابعة الطلبية",
    order_number: "رقم الطلبية",
    from: "من",
    by: "بواسطة",
    to: "الي",
    payment: "عملية الدفع",
    ordered: "اصدار الطلبية",
    shipped: "شحن",
    delivery: "خارج للتوصيل",
    order_details: "تفاصيل الطلب",
  },

  quote: {
    title: "احصل علي تسعيرة",
    personal_info: "المعلومات الشخصية",
    car_details: "بيانات السيارة",
    make: "الصانع",
    year: "السنة",
    model: "الموديل",
    fuel: "الوقود",
  },

  footer: {
    home: "الرئيسية",
    brands: "العلامات التجارية",
    about: "من نحن",
    contact_us: "تواصل معنا",
    terms: "البنود و الشروط",
    faq: "الأسئلة الشائعة",
    career: "الوظائف",
    profile: "ملف الشركة",
    contact: "تواصل معنا",
    email_news: "احصل علي اخر المستجدات علي بريدك الالكتوروني",
    open_in_your_phone: "امسح الرمز لإستخدام  الجوال",
    email_address: "عنوان البريد الالكترونى",

    reserved: "جميع الحقوق محفوظة © محفوظة alkarama.com",
    designed: "برين كليك LLC",
  },

  system: {
    button: {
      text1: "إرسال",
      text2: "ارسال",
      text3: "اقرأ المزيد",
      text4: "إلغاء",
      text5: "تم النسخ",
      text6: "تفاصيل اكثر",
    },
    errors: {
      text1: "يوجد خلل في الخادم الرجاء المحاولة مرة اخرى",
      text2: "السيارة غير متوفرة",
    },
    dir: "rtl",
    dir_reverse: "ltr",
    absolute_left_right_10: "absolute-right-10",
  },
  style: {
    header_profile_icon: "header-profile-icon-arabic",
    hero_text_inner: "hero-text-inner-arabic",
    booster_left_inner: "booster-left-inner-arabic",
    article_like: "article_like_arabic",
    delete_arrow: "delete-arrow-arabic",
    slider_left: "slider-left-arabic",
    slider_right: "slider-right-arabic",
    float: "left",
    sidebar: "start",
  },
};

export const English = {
  viewAll: "View All",
  current: "en",
  name: "English",
  header: {
    home: "Home",
    brand: {
      brand: "Brand",
      nissan: "Nissan",
      toyota: "Toyota",
      lexus: "Lexus",
      volkswagen: "Volkswagen",
      honda: "Honda",
      mitsubishi: "Mitsubishi",
      suzuki: "Suzuki",
    },
    car_type: {
      car_type: "Car Type",
      micro: "Micro",
      sedan: "Sedan",
      hatchback: "Hatchback",
      suv: "Suv",
      pickup: "Pickup",
      sport: "Sport",
    },
    about_company: {
      about_company: "About Company",
      about: "About Us",
      contact: "Contact Us",
      career: "Career",
      faq: "FAQ",
      company_profile: "Company Profile",
    },
    allStock: "All Stock",
    enquiries: "Enquiries",
    otherLanguages: [
      {
        id: "ar",
        label: "عربي",
      },
      {
        id: "fr",
        label: "Français",
      },
      {
        id: "ru",
        label: "Русский",
      },
    ],
    blog: "Cars-Blog"
  },

  home: {
    hero: {
      more_details: "More Details",
      search: {
        title: "Search",
        more_filter_mobile: "Show All Features",
        more_filter: "More_Filters",
        less_filter: "Less_Filters",
        maker: {
          title: "Maker",
          nissan: "Nissan",
          toyota: "Toyota",
          lexus: "Lexus",
          volkswagen: "Volkswagen",
          mitsubishi: "Mitsubishi",
          suzuki: "Suzuki",
        },
        model: {
          title: "Model",
          sunny: "Sunny",
          altima: "Altima",
          maxima: "Maxima",
          patrol: "Patrol",
          patrol_safari: "Patrol Safari",
          pathfinder: "Pathfinder",
        },
        year: {
          title: "Year",
          min: "Min Year",
          max: "Max Year",
        },
        status: {
          title: "Status",
          all: "All",
          new: "New",
          used: "Used",
        },
        fuel: {
          title: "Fuel Type",
          all: "All",
          gasoline: "Gasoline",
          diesel: "Diesel",
          hybrid: "Hybrid",
          electric: "Electric",
        },
        cylinder: {
          title: "Cylinder",
          all: "All",
          none: "None",
        },
        gcc: {
          title: "Regional Specs",
          gcc: "GCC",
          import: "Import",
        },
        body_type: {
          title: "Vechicle Type",
          micro: "Micro",
          hatchback: "Hatchback",
          sedan: "Sedan",
          suv: "SUV",
          pickup: "Pickup",
          sport: "Sport",
        },
        color: {
          title: "Color",
          white: "White",
          black: "Black",
          blue: "Blue",
          red: "Red",
          gray: "Gray",
          silver: "Silver",
          green: "Green",
          brown: "Brown",
        },
        gear_box: {
          title: "Transmission/Gearbox",
          all: "All",
          automatic: "Automatic",
          manual: "Manual",
        },
        seats: {
          title: "Seats",
          all: "All",
        },
      },
    },

    premium: "PREMIUM CARS",
    new: "NEW CARS",
    popular: "POPULAR CARS",
    used: "USED CARS",
    offer: "OUR OFFERS",
    export: "CAR FOR EXPORT",
    partner: "OUR PARTNERS",
    showroom: "CARS FOR OTHER SHOWROOM",
    news: "CAR NEWS",
    last_review: "OUR LAST REVIEW",
    social_media: "SOCIAL MEDIA",
    instagram: "Instagram",
    location: {
      title: "OUR LOCATION",
      title1: "Showroom N° - Ras Al Khor - Dubai - UAE",
      title2: "Showroom N° - Ras Al Khor - Dubai - UAE",
      title3: "Showroom N° - Ras Al Khor - Dubai - UAE",
    },
  },

  about: {
    values: "Our Core Values",
    team: "Our Team",
  },

  contact: {
    support: "24/7 Support",
    have_questions: "Have any Questions?",
    ask_us: "Ask Us..!!",
    get_quote: "Get Quote",
    form: {
      name: "Name*",
      name_validate: "The name field is required.",
      email: "Email",
      email_validate: "The email must be a valid email address.",
      phone: "Phone*",
      phone_validate: "The phone field is required.",
      country: "Select Country",
      country_validate: "The country field is required.",
      message: "Message ...",
      message_validate: "The message field is required.",
      button_text: "SEND MESSAGE",
      result_validate: "Thank you, You will get The quote via your email",
      error_validate: "Choose car details please",
    },
  },

  career: {
    title: "Careers",
    sub_title:
      "Didn't find a suitable and available vacancy? Send your CV using the form below or email to: careers@alkarama.com",
    job_title: "Job Title",
    position: "Position",
    job_description: "Job Description",
    description: "Description",
    no_job: "No position available",
    submit: "Submit",
    apply: "Apply",
    email: "careers@alkarama.com",
    form: {
      name: "Name*",
      name_validate: "The name field is required.",
      phone: "Phone*",
      phone_validate: "The phone field is required.",
      email: "Email*",
      email_validate: "The email must be a valid email address.",
      cv: "CV*",
      cv_validate: "Upload your CV please",
      result_succed: "Your job request Sent successfully.",
      button_text: "SUBMIT",
    },
  },

  car: {
    menu: {
      specs: "Specs",
      gallery: "Gallery",
      about: "About Car",
      colors: "Colors",
      ask_us: "Ask Us",
      similar: "SIMILAR VEHICLES",
      all: "All",
      interior: "Interior",
      exterior: "Exterior",
      video: "Video",
    },
    specs: {
      downloadPdf: "Download PDF",
      year: "Year: ",
      mileage: "Mileage: ",
      specs: "Specs: ",
      fuel: "Fuel: ",
      engine: "Engine: ",
      doors: "Doors: ",
      transmission: "Transmission: ",
      speed: "Speed: ",
      drive: "Drive: ",
      seats: "Seats: ",
      steeringSide: "Steering Side: ",
      similar: "View Full Specification",
      back: "B A C K   T O   C A R",
    },
    similar: "SIMILAR VEHICLES",
    interior: "INTERIOR DESIGN",
    exterior: "EXTERIOR FEATURES",
    environment: "SECURITY & ENVIRONMENT",
  },

  brand: {
    title: "Brands",
  },

  order: {
    track: "Track order",
    order_number: "Order N°",
    from: "From",
    by: "By",
    to: "To",
    payment: "Payment",
    ordered: "Ordered",
    shipped: "Shipped",
    delivery: "Out For Delivery",
    order_details: "Order Details",
  },

  quote: {
    title: "GET QUOTE",
    personal_info: "Personal information",
    car_details: "Car details",
    make: "Make",
    year: "Year",
    model: "Model",
    fuel: "Fuel",
  },

  footer: {
    home: "Home",
    brands: "Brands",
    about: "About Us",
    contact_us: "Contact Us",
    terms: "Terms & Conditions",
    faq: "FAQ",
    career: "Careers",
    profile: "Company Profile",
    contact: "Contact",
    email_news: "Updates right to your inbox",
    open_in_your_phone: "Scan for Mobile Version",
    email_address: "Email Address",

    reserved: "All Copyright © Reserved alkaramamotors.com",
    designed: "Brain Click LLC",
  },

  system: {
    button: {
      text1: "Send",
      text2: "Supmit",
      text3: "Read More",
      text4: "Cancel",
      text5: "Coped",
      text6: "More Details",
    },
    errors: {
      text1: "Server error please try again",
      text2: "No car available",
    },
    dir: "ltr",
    dir_reverse: "rtl",
    absolute_left_right_10: "absolute-left-10",
  },

  style: {
    header_profile_icon: "header-profile-icon-english",
    hero_text_inner: "hero-text-inner-english",
    booster_left_inner: "booster-left-inner-english",
    article_like: "article_like_english",
    delete_arrow: "delete-arrow-english",
    slider_left: "slider-left-english",
    slider_right: "slider-right-english",
    float: "right",
    sidebar: "end",
  },
};

export const French = {
  viewAll: "Voir tout",
  current: "fr",
  name: "Français",
  header: {
    home: "Page d’accueil",
    brand: {
      brand: "Marque",
      nissan: "Nissan",
      toyota: "Toyota",
      lexus: "Lexus",
      volkswagen: "Volkswagen",
      honda: "Honda",
      mitsubishi: "Mitsubishi",
      suzuki: "Suzuki",
    },
    car_type: {
      car_type: "Type de voiture",
      micro: "Micro",
      sedan: "Sedan",
      hatchback: "Hatchback",
      suv: "VUS",
      pickup: "Pickup",
      sport: "sportive",
    },
    about_company: {
      about_company: "À propos de la société",
      about: "À propos de nous",
      contact: "Nous contacter",
      career: "carrière",
      faq: "Questions fréquemment posées",
      company_profile: "Profil de l’entreprise",
    },
    allStock: "Tous les stocks",
    enquiries: "Les Enquêtes",
    otherLanguages: [
      {
        id: "en",
        label: "English",
      },
      {
        id: "ar",
        label: "عربي",
      },
      {
        id: "ru",
        label: "Русский",
      },
    ],
    blog: "Blog de voitures"
  },
  home: {
    hero: {
      more_details: "Plus de détails",
      search: {
        title: "Recherche",
        more_filter_mobile: "Afficher toutes les fonctionnalités",
        more_filter: "Plus_Filtres",
        less_filter: "Moins_Filtres",
        maker: {
          title: "Fabricant",
          nissan: "Nissan",
          toyota: "Toyota",
          lexus: "Lexus",
          volkswagen: "Volkswagen",
          mitsubishi: "Mitsubishi",
          suzuki: "Suzuki",
        },
        model: {
          title: "Modèle",
          sunny: "Ensoleillé",
          altima: "Altima",
          maxima: "Maximum",
          patrol: "Patrouille",
          patrol_safari: "Safari de patrouille",
          pathfinder: "Pathfinder",
        },
        year: {
          title: "Année",
          min: "Année minimale",
          max: "Année maximale",
        },
        status: {
          title: "Statut",
          all: "Tous",
          new: "Nouveau",
          used: "Utilisé",
        },
        fuel: {
          title: "Type de carburant",
          all: "Tous",
          gasoline: "De l’essence",
          diesel: "Diesel",
          hybrid: "Hybride",
          electric: "Électrique",
        },
        cylinder: {
          title: "Cylindre",
          all: "Tous",
          none: "Aucun",
        },
        gcc: {
          title: "Spécifications régionales",
          gcc: "CCG",
          import: "Importer",
        },
        body_type: {
          title: "Type de véhicule",
          micro: "Micro",
          hatchback: "Hatchback",
          sedan: "Sedan",
          suv: "VUS",
          pickup: "Pickup",
          sport: "Sportive",
        },
        color: {
          title: "Couleur",
          white: "Blanc",
          black: "Noir",
          blue: "Bleu",
          red: "Rouge",
          gray: "Gris",
          silver: "Argent",
          green: "Vert",
          brown: "Marron",
        },
        gear_box: {
          title: "Transmission/boîte de vitesses",
          all: "Tous",
          automatic: "Automatique",
          manual: "Manuel",
        },
        seats: {
          title: "Des places",
          all: "Tous",
        },
      },
    },
    premium: "VOITURE PREMIUM",
    new: "NOUVELLES VOITURES",
    popular: "VOITURES POPULAIRES",
    used: "VÉHICULES D’OCCASION",
    offer: "NOS OFFRES",
    export: "VOITURE POUR L’EXPORTATION",
    partner: "NOS PARTENAIRES",
    showroom: "VOITURES POUR AUTRES SHOWROOM",
    news: "ACTUALITÉS VOITURE",
    last_review: "NOTRE DERNIÈRE REVUE",
    social_media: "DES MÉDIAS SOCIAUX",
    instagram: "Instagram",
    location: {
      title: "NOTRE EMPLACEMENT",
      title1: "Showroom N° - Ras Al Khor – Dubaï - EAU",
      title2: "Showroom N° - Ras Al Khor – Dubaï - EAU",
      title3: " Showroom N° - Ras Al Khor - Dubaï - EAU",
    },
  },
  about: {
    values: "Nos valeurs fondamentales",
    team: "Notre équipe",
  },
  contact: {
    support: "Assistance 24h/24 et 7j/7",
    have_questions: "Avez-vous des questions?",
    ask_us: "Demandez nous..!!",
    get_quote: "Obtenir un devis",
    form: {
      name: "Nom*",
      name_validate: "Il est requis de compléter le champ correspondant au nom.",
      email: "E-mail",
      email_validate: "L’e-mail doit être une adresse e-mail valide.",
      phone: "Téléphone fixe*",
      phone_validate: " Le champ téléphone est obligatoire.",
      country: "Choisissez le pays",
      country_validate: "Le champ pays est obligatoire.",
      message: "Message ...",
      message_validate: "Le champ message est obligatoire.",
      button_text: "ENVOYER LE MESSAGE",
      result_validate: "Merci, vous recevrez le devis via votre e-mail",
      error_validate: "Choisissez les détails de la voiture s’il vous plaît",
    },
  },
  career: {
    title: "Carrières",
    sub_title:
      " Vous n'avez pas trouvé d'offre d'emploi adaptée et disponible ? Envoyez votre CV via le formulaire ci-dessous ou par e-mail à : careers@alkarama.com",
    job_title: "Profession",
    position: "Positionner",
    job_description: "Description de l’emploi",
    description: "La description",
    no_job: "Aucun poste disponible",
    submit: "Nous faire parvenir",
    apply: "Appliquer",
    email: "careers@alkarama.com",
    form: {
      name: "Nom*",
      name_validate: "Il est requis de compléter le champ correspondant au nom.",
      phone: "Téléphone fixe*",
      phone_validate: "Le champ téléphone est obligatoire.",
      email: "E-mail*",
      email_validate: "L’e-mail doit être une adresse e-mail valide.",
      cv: "CV*",
      cv_validate: "Téléchargez votre CV s’il vous plaît",
      result_succed: "Votre demande d’emploi a été envoyée avec succès.",
      button_text: "NOUS FAIRE PARVENIR",
    },
  },
  car: {
    menu: {
      specs: "Spécifications",
      gallery: "Galerie",
      about: "À propos de la voiture",
      colors: "Couleurs",
      ask_us: "Demandez nous",
      similar: "VÉHICULES SIMILAIRES",
      all: "Tous",
      interior: "Intérieur",
      exterior: "Extérieur",
      video: "Vidéo",
    },
    specs: {
      downloadPdf: "Télécharger le PDF",
      year: "Année: ",
      mileage: "Kilométrage: ",
      specs: "Spécifications: ",
      fuel: "Carburant: ",
      engine: "Moteur: ",
      doors: "Des portes: ",
      transmission: "Transmission: ",
      speed: "Vitesse: ",
      drive: "Lecteur: ",
      seats: "Des places: ",
      steeringSide: "Côté volant : ",
      similar: "Afficher les spécifications complètes",
      back: "RETOUR À LA CARRIÈRE",
    },
    similar: "VÉHICULES SIMILAIRES",
    interior: "AMÉNAGEMENT D’INTÉRIEUR ",
    exterior: "ÉLÉMENTS EXTÉRIEURS",
    environment: "SÉCURITÉ & ENVIRONNEMENT",
  },
  brand: {
    title: "Marques",
  },
  order: {
    track: "Suivi de commande",
    order_number: "N° de commande",
    from: "À partir de",
    by: "Par",
    to: "Pour",
    payment: "Paiement",
    ordered: "Commandé",
    shipped: "Expédié",
    delivery: "En cours de livraison",
    order_details: "Détails de la commande",
  },
  quote: {
    title: "OBTENIR UN DEVIS",
    personal_info: "Informations personnelles",
    car_details: "Détails de la voiture",
    make: "Faire",
    year: "Année",
    model: "Modèle",
    fuel: "Carburant",
  },
  footer: {
    home: "Page d’accueil",
    brands: "Marques",
    about: "À propos de nous",
    contact_us: "Nous contacter",
    terms: "Termes et conditions",
    faq: "Questions fréquemment posées",
    career: "Carrières",
    profile: "Profil de l’entreprise",
    contact: "Contacter",
    email_news: "mises à jour directement dans votre boîte de réception",
    open_in_your_phone: "Rechercher la version mobile",
    email_address: "Adresse e-mail",
    reserved: " Tous Copyright © Réservé alkaramamotors.com",
    designed: "Brain Click LLC",
  },
  system: {
    button: {
      text1: "Envoyer",
      text2: "Sommet",
      text3: "Lire la suite",
      text4: "Annuler",
      text5: "Faire face",
      text6: "Plus de détails",
    },
    errors: {
      text1: "Erreur de serveur, veuillez réessayer",
      text2: "Pas de voiture disponible",
    },
    dir: "ltr",
    dir_reverse: "rtl",
    absolute_left_right_10: "absolu-gauche-10",
  },
  style: {
    header_profile_icon: "header-profile-icon-francais",
    hero_text_inner: "héros-texte-intérieur-francais",
    booster_left_inner: "booster-gauche-intérieur-francais",
    article_like: "article_like_francais",
    delete_arrow: "delete-fleche- francais",
    slider_left: "curseur-droite- francais",
    slider_right: "curseur-gauche-francais",
    float: "droite",
    sidebar: "finir",
  },
};

export const Russian = {
  viewAll: "Посмотреть все",
  current: "ru",
  name: "Русский",
  header: {
    home: "домашняя страница",
    brand: {
      brand: "Бренд",
      nissan: "Ниссан",
      toyota: "Тойота",
      lexus: "Лексус",
      volkswagen: "фольксваген",
      honda: "Хонда",
      mitsubishi: "Мицубиси",
      suzuki: "Сузуки",
    },
    car_type: {
      car_type: "Тип автомобиля",
      micro: "Микро",
      sedan: "Седан",
      hatchback: "Хэтчбек",
      suv: "Внедорожник",
      pickup: "Пикап",
      sport: "Спорт",
    },
    about_company: {
      about_company: "О компании",
      about: "О нас",
      contact: "Контакты",
      career: "Карьера",
      faq: "Часто задаваемые вопросы",
      company_profile: "Профиль компании",
    },
    allStock: "Все в наличии",
    enquiries: " спрашивать",
    otherLanguages: [
      {
        id: "en",
        label: "English",
      },
      {
        id: "ar",
        label: "عربي",
      },
      {
        id: "fr",
        label: "Français",
      },
    ],
  },
  home: {
    hero: {
      more_details: "Подробнее",
      search: {
        title: "Поиск",
        more_filter_mobile: "Показать все функции",
        more_filter: "Дополнительные_Фильтры",
        less_filter: "Меньше_фильтров",
        maker: {
          title: "Создатель",
          nissan: "Ниссан",
          toyota: "Тойота",
          lexus: "Лексус",
          volkswagen: "фольксваген",
          mitsubishi: "Мицубиси",
          suzuki: "Сузуки",
        },
        model: {
          title: "Модель",
          sunny: "Солнечно",
          altima: "Альтима",
          maxima: "Максима",
          patrol: "Патруль",
          patrol_safari: "Патрульное сафари",
          pathfinder: "Следопыт",
        },
        year: {
          title: "Год",
          min: "Минимальный год",
          max: "Максимальный год",
        },
        status: {
          title: "Статус",
          all: "Все",
          new: "Новый",
          used: "использовал",
        },
        fuel: {
          title: "Тип топлива",
          all: "Все",
          gasoline: "Бензин",
          diesel: "Дизель",
          hybrid: "Гибрид",
          electric: "Электрик",
        },
        cylinder: {
          title: "Цилиндр",
          all: "Все",
          none: "Нет",
        },
        gcc: {
          title: "Региональные характеристики",
          gcc: "ССЗ",
          import: "Импорт",
        },
        body_type: {
          title: "Тип транспортного средства",
          micro: "Микро",
          hatchback: "Хэтчбек",
          sedan: "Седан",
          suv: "внедорожник",
          pickup: "Пикап",
          sport: "Спорт",
        },
        color: {
          title: "Цвет",
          white: "Белый",
          black: "черный",
          blue: "Синий",
          red: "Красный",
          gray: "Серый",
          silver: "Серебро",
          green: "Зеленый",
          brown: "Коричневый",
        },
        gear_box: {
          title: "Трансмиссия/Коробка передач",
          all: "Все",
          automatic: "Авто",
          manual: " Ручной",
        },
        seats: {
          title: "Места",
          all: "Все",
        },
      },
    },
    premium: "ПРЕМИУМ АВТО",
    new: "НОВЫЕ АВТОМОБИЛИ",
    popular: "ПОПУЛЯРНЫЕ АВТОМОБИЛИ",
    used: "ПОЛУЖЕННЫЕ АВТОМОБИЛИ",
    offer: "НАШИ ПРЕДЛОЖЕНИЯ",
    export: "АВТОМОБИЛЬ НА ЭКСПОРТ",
    partner: "НАШИ ПАРТНЕРЫ",
    showroom: "АВТОМОБИЛИ ДЛЯ ДРУГОГО САЛОН",
    news: "АВТО НОВОСТИ",
    last_review: "НАШ ПОСЛЕДНИЙ ОБЗОР",
    social_media: "СОЦИАЛЬНЫЕ МЕДИА",
    instagram: "Инстаграм",
    location: {
      title: "НАШЕ МЕСТОПОЛОЖЕНИЕ",
      title1: "Выставочный зал № – Рас-Аль-Хор – Дубай – ОАЭ",
      title2: " Выставочный зал № – Рас-Аль-Хор – Дубай – ОАЭ",
      title3: " Выставочный зал № – Рас-Аль-Хор – Дубай – ОАЭ",
    },
  },
  about: {
    values: "Наши основные ценности",
    team: "Наша команда",
  },
  contact: {
    support: " Поддержка 24/7",
    have_questions: "Есть вопросы?",
    ask_us: "Спросите нас..!!",
    get_quote: "Получить цитату",
    form: {
      name: "Имя*",
      name_validate: "Поле имени обязательно.",
      email: "Электронная почта",
      email_validate: "Электронная почта должна быть действительным адресом электронной почты.",
      phone: "Телефон*",
      phone_validate: "Поле телефона обязательно.",
      country: "Выберите страну",
      country_validate: "Поле страны обязательно.",
      message: "Сообщение ...",
      message_validate: "Поле сообщения обязательно.",
      button_text: "ОТПРАВИТЬ СООБЩЕНИЕ",
      result_validate: "Спасибо, вы получите предложение по электронной почте",
      error_validate: "Выберите детали автомобиля, пожалуйста",
    },
  },
  career: {
    title: "Карьера",
    sub_title:
      "Не нашли подходящей и доступной вакансии? Отправьте свое резюме, используя форму ниже, или по электронной почте:cares@alkarama.com",
    job_title: "Название должности",
    position: "Позиция",
    job_description: "Описание работы",
    description: "Описание",
    no_job: "Нет доступных вакансий",
    submit: "Отправить",
    apply: "Применить",
    email: "careers@alkarama.com",
    form: {
      name: "Имя*",
      name_validate: "Поле имени обязательно.",
      phone: "Телефон*",
      phone_validate: "Поле телефона обязательно.",
      email: "Электронная почта*",
      email_validate: "Электронная почта должна быть действительным адресом электронной почты.",
      cv: "Резюме*",
      cv_validate: "Загрузите свое резюме, пожалуйста",
      result_succed: "Ваш запрос на работу успешно отправлен.",
      button_text: "ОТПРАВИТЬ",
    },
  },
  car: {
    menu: {
      specs: "Характеристики",
      gallery: "Галерея",
      about: "о машине",
      colors: "Цвета",
      ask_us: "Спросите нас",
      similar: "ПОДОБНЫЕ ТРАНСПОРТНЫЕ СРЕДСТВА",
      all: "Все",
      interior: "Интерьер",
      exterior: "Экстерьер",
      video: "Видео",
    },
    specs: {
      downloadPdf: "Скачать PDF",
      year: "Год: ",
      mileage: "Пробег: ",
      specs: "Характеристики: ",
      fuel: "Топливо: ",
      engine: "Двигатель: ",
      doors: "Двери: ",
      transmission: "Коробка передач: ",
      speed: "Скорость: ",
      drive: "Диск: ",
      seats: "Места: ",
      steeringSide: "Сторона руля: ",
      similar: "Просмотреть полную спецификацию",
      back: "Б А К Т О Ц А Р",
    },
    similar: "ПОДОБНЫЕ ТРАНСПОРТНЫЕ СРЕДСТВА",
    interior: "ДИЗАЙН ИНТЕРЬЕРА",
    exterior: "ВНЕШНИЕ ХАРАКТЕРИСТИКИ",
    environment: "БЕЗОПАСНОСТЬ И ОКРУЖАЮЩАЯ СРЕДА",
  },
  brand: {
    title: "Бренды",
  },
  order: {
    track: "Отследить заказ",
    order_number: "Номер заказа",
    from: "От",
    by: "По",
    to: "К",
    payment: "Оплата",
    ordered: "Заказано",
    shipped: "Отправлено",
    delivery: "Выезд на доставку",
    order_details: "Детали заказа",
  },
  quote: {
    title: "ПОЛУЧИТЬ ЦИТАТУ",
    personal_info: "Личная информация",
    car_details: "Детали автомобиля",
    make: "Сделать",
    year: "Год",
    model: "Модель",
    fuel: "Топливо",
  },
  footer: {
    home: "домашняя страница",
    brands: "Бренды",
    about: "О нас",
    contact_us: "Контакты",
    terms: "Условия и положения",
    faq: "Часто задаваемые вопросы",
    career: "Карьера",
    profile: "Профиль компании",
    contact: "Контакты",
    email_news: "обновления прямо в ваш почтовый ящик",
    open_in_your_phone: "Поиск мобильной версии",
    email_address: "Адрес электронной почты",
    reserved: "Все авторские права © защищены alkaramamotors.com",
    designed: "ООО «Брейн клик»",
  },
  system: {
    button: {
      text1: "Отправить",
      text2: "Подтвердить",
      text3: "Читать больше",
      text4: "Отмена",
      text5: "Справились",
      text6: "Подробнее",
    },
    errors: {
      text1: "Ошибка сервера, попробуйте еще раз",
      text2: "Автомобиля нет",
    },
    dir: "лтр",
    dir_reverse: "ртл",
    absolute_left_right_10: "абсолютно-лево-10",
  },
  style: {
    header_profile_icon: "значок-профиля-в шапке-Русский",
    hero_text_inner: "герой-текст-внутренне-русский",
    booster_left_inner: "бустер-левый-внутренний-русский",
    article_like: "статья_вроде_русский",
    delete_arrow: "удалить-стрелка-русский",
    slider_left: "слайдер-левый-русский",
    slider_right: "слайдер-право-русский",
    float: "право",
    sidebar: "конец",
  },
};
