import React, { useCallback } from "react";
import { createContext, useState } from "react";
import { English } from "../Configration/Language";
import Variables from "../Configration/Variables";
import $ from "jquery";
import Loading from "../Components/common/loading";

export const DataContext = createContext();

export const DataProvider = (props) => {
  const [language, setLanguage] = useState(English);
  const [startData, setStartData] = useState(null);
  const [filters, setFilters] = useState(null);
  const [homeData, setHomeData] = useState(null);
  const [brands, setBrands] = useState(null);

  const [query, setQuery] = useState([
    { key: "body_type", value: null },
    { key: "colors", value: null },
    { key: "engine", value: null },
    { key: "fuel", value: null },
    { key: "source", value: null },
    { key: "transmission", value: null },
    { key: "brands", value: null },
    { key: "models", value: null },
    { key: "seats", value: null },
    { key: "status", value: null },
    { key: "min_year", value: null },
    { key: "max_year", value: null },
    { key: "text", value: null },
  ]);

  const [currentCar, setCurrentCar] = useState(null);

  const getStartData = useCallback(() => {
    $.ajax({
      url: `${Variables.baseUrl}/start`,
      type: "get",
      data: {},
      beforeSend: function () {},
      headers: {
        Accept: "application/json",
        lang: language.current,
      },
      success: function (data) {
        setStartData(data?.data);
      },
      error: function (error) {},
    });
  }, [language]);

  const getFilters = useCallback(() => {
    $.ajax({
      url: `${Variables.baseUrl}/filters`,
      type: "get",
      data: {},
      beforeSend: function () {},
      headers: {
        Accept: "application/json",
        lang: language.current,
      },
      success: function (data) {
        setFilters(data?.data);
      },
      error: function (error) {},
    });
  }, [language]);

  const getHome = useCallback(() => {
    $.ajax({
      url: `${Variables.baseUrl}/home`,
      type: "get",
      data: {},
      beforeSend: function () {},
      headers: {
        Accept: "application/json",
        lang: language.current,
      },
      success: function (data) {
        setHomeData(data?.data);
      },
      error: function (error) {},
    });
  }, [language]);

  const getBrands = useCallback(() => {
    $.ajax({
      url: `${Variables.baseUrl}/brands?limit=100&offset=1`,
      type: "get",
      data: {},
      beforeSend: function () {},
      headers: {
        Accept: "application/json",
        lang: language.current,
      },
      success: function (data) {
        setBrands(data?.data?.data);
      },
      error: function (error) {},
    });
  }, [language]);

  React.useEffect(() => {
    getStartData();
  }, [getStartData]);

  React.useEffect(() => {
    if (startData) {
      getFilters();
      getHome();
      getBrands();
    }
  }, [startData, getFilters, getHome, getBrands]);

  const isFetched = startData;

  return (
    <DataContext.Provider
      value={{
        language,
        setLanguage,
        data: startData,
        setData: setStartData,
        startData,
        setStartData,
        filter: filters,
        setFilter: setFilters,
        query,
        setQuery,
        currentCar,
        setCurrentCar,
        homeData,
        brands,
      }}
    >
      {isFetched && props?.children}
      {!isFetched && <Loading />}
    </DataContext.Provider>
  );
};
